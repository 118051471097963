
div#head{
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

div#decks{
  width: 80%;
  max-width: 1000px;
  /* min-width: 700px; */
  height: 65vh;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

#fakeBody{
  height: 100vh;
  overflow-y: scroll;
}

#fakeContent{
  height: 400vh;
}

#realBody{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top:0;
  width: 100%;
  height: 100vh;
  margin: 0;
  overflow-y: hidden;
}

@media only screen and (max-width: 1000px) {
  div#decks{
    /* background-color: black; */
    flex-direction: column;
    justify-content: center;
  }

  div#foot{
    background-color: rgba(255, 255, 255, 0.4);
  }
}
/* div#footnote */
div#foot{
  position: relative;
  z-index: 10;
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

div#foot > div{
  margin-bottom: 10px;
}

div#leftDeck{
  position: relative;
  width: 1px;
  height: 400px;
}

div#rightDeck{
  position: relative;
  width: 1px;
  height: 400px;
}
