.card{
  position: absolute;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  box-sizing: border-box;
  font-size: 0.9em;
  font-weight: 800;
  width: 10em;
  height: 15.5em;
  border-radius: 1em;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  transition: all 0.3s;
}

@media only screen and (max-width: 1000px) {
  .card{
    width: 20vh;
    height: 30vh;
    font-size: 0.5em;
  }
}

.handOfCards{
  animation: 0.3s ease-out 0s 1 cardAppearAnimation;
  transition: margin-top 0.3s;
}

@keyframes cardAppearAnimation{
  0% {
    margin-top: 90vh;
  }
  100% {
    margin-top: 0;
  }
}

.handOfCards.removed{
  margin-top: 90vh;
}


.linkCard{
  color: #086788 !important;
  /* color: white !important; */
  background-color: #F0C808 !important;
}

.linkCard:hover{
  cursor: pointer;
  -webkit-box-shadow: -4px 2px 32px -15px rgba(0,0,0,1);
  -moz-box-shadow: -4px 2px 32px -15px rgba(0,0,0,1);
  box-shadow: -4px 2px 32px -15px rgba(0,0,0,1);
}

.masterCard{
  color: #FFFFFF;
  background-color: #232323;
  -webkit-box-shadow: -4px 2px 32px -15px rgba(0,0,0,1);
  -moz-box-shadow: -4px 2px 32px -15px rgba(0,0,0,1);
  box-shadow: -4px 2px 32px -15px rgba(0,0,0,1);
}

.answerCard{
  color: #232323;
  background-color: #FFFFFF;
  -webkit-box-shadow: -4px 2px 35px -22px rgba(0,0,0,0.46);
  -moz-box-shadow: -4px 2px 35px -22px rgba(0,0,0,0.46);
  box-shadow: -4px 2px 35px -22px rgba(0,0,0,0.46);
}
